<template>
    <a-modal :visible='visible' title="详情" :footer='null' @cancel='cancel' width='640px'>



          <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">事故名称</div>
      <div class="">
        {{ detail.accName }}
      </div>
    </a-descriptions-item>
        <a-descriptions-item>
      <div slot="label" class="center">事故单位</div>
      <div class="">
        {{ detail.accOrg }}
      </div>
    </a-descriptions-item>

            <a-descriptions-item>
      <div slot="label" class="center">事故发生时间</div>
      <div class="">
        {{ detail.accTime }}
      </div>
    </a-descriptions-item>

            <a-descriptions-item >
      <div slot="label" class="center">事故发生地点</div>
      <div class="">
        {{ detail.accLocation }}
      </div>
    </a-descriptions-item>

                <a-descriptions-item >
      <div slot="label" class="center">事故类别</div>
      <div class="">
          <DataDictFinder dictType="acc_category" :dictValue="detail.accCategory" />
      </div>
    </a-descriptions-item>
                    <a-descriptions-item >
      <div slot="label" class="center">事故级别</div>
      <div class="">
          <DataDictFinder dictType="acc_level" :dictValue="detail.accLevel" />
      </div>
    </a-descriptions-item>

                <a-descriptions-item :span='2'>
      <div slot="label" class="center">人员伤亡情况</div>
      <div class="">
        {{ detail.casualties }}
      </div>
    </a-descriptions-item>

                <a-descriptions-item :span='2'>
      <div slot="label" class="center">直接经济损失(万)</div>
      <div class="">
        {{ detail.directEconomicLoss }}
      </div>
    </a-descriptions-item>

                <a-descriptions-item :span='2'>
      <div slot="label" class="center">事故简况</div>
      <div class="">
        {{ detail.accDesc }}
      </div>
    </a-descriptions-item>

  </a-descriptions>


    </a-modal>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },


  methods: {
    cancel() {
      this.$emit('cancel')
    },

  }
};
</script>
