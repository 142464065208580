<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.accName"
                placeholder="事故名称"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input
                v-model="form.accOrg"
                placeholder="事故单位"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

                 <a-form-model-item>
          <a-range-picker
            :placeholder="['事故发生时间', '事故发生时间']"
            v-model="form.date"
            style="width: 316px"
          />
        </a-form-model-item>

          <a-form-model-item>
            <a-select v-model="form.accCategory" style="width: 150px" placeholder="事故类别">
              <a-select-option v-for="item in acc_category" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item> 

              <a-form-model-item>
            <a-select v-model="form.accLevel" style="width: 150px" placeholder="事故级别">
              <a-select-option v-for="item in acc_level" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item> 

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button 
                v-if="$getPermission($route.path + '/add')"
            @click="$router.push($route.path + '/add')" type="primary"
              >新增</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey='id'
      >
        <a-table-column title="事故名称" data-index="accName" align='center'> </a-table-column>
        <a-table-column title="事故单位" data-index="accOrg" align='center'> </a-table-column>
        <a-table-column title="事故发生时间" data-index="accTime"> </a-table-column>
        <a-table-column title="事故发生地点" data-index="accLocation" align='center'> </a-table-column>
        <a-table-column title="事故类别" data-index="accCategory" align='center'> 
          <template slot-scope="accCategory">
            <DataDictFinder dictType="acc_category" :dictValue="accCategory" />
          </template>
        </a-table-column>
        <a-table-column title="事故级别" data-index="accLevel" align='center'> 
             <template slot-scope="accLevel">
             <DataDictFinder dictType="acc_level" :dictValue="accLevel" />
             </template>
        </a-table-column>
        <a-table-column title="人员伤亡情况" data-index="casualties" align='center'> </a-table-column>
        <a-table-column title="直接经济损失(万)" data-index="directEconomicLoss" align='center'> </a-table-column>
        <a-table-column title="事故简况" data-index="accDesc" align='center'> </a-table-column>

        <a-table-column title="操作" align="center" width="100px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="openDetail(text)"
                >详情</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <Detail :visible='visible' :detail='detail' @cancel='visible = false' />
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { mapGetters } from 'vuex'
import request from "@/api/request";
import Detail from './components/detail.vue'

function fetchList (data) {
  return request({
      url: '/office-service/quality/acc/report/queryListWithPage',
    method: 'post',
    data,
  })
}

export default {
  mixins: [watermark],

  components: {
    Detail
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      detail: {},
      visible: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    acc_level() {
      return this.findDataDict("acc_level");
    },

    acc_category() {
      return this.findDataDict("acc_category");
    },

  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;

          let startTime, endTime;

        if (Array.isArray(form.date) && form.date.length === 2) {
            startTime = form.date[0].format("YYYY-MM-DD");
            endTime = form.date[1].format("YYYY-MM-DD");
          }

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
        date: undefined,
        startTime,
        endTime,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    openDetail(text) {
      this.detail = text;
      this.visible = true;
    },


  },
};
</script>